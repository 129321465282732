@font-face {
  font-family: "Akkurat Light";
  src: local("Akkurat Light"),
    url(./assets/fonts/akkurat-light.woff) format("woff");
}
@font-face {
  font-family: "Akkurat Bold";
  src: local("Akkurat Bold"),
    url(./assets/fonts/akkurat-bold.woff) format("woff");
}

a {
  color: #000;
  font-family: "Akkurat Light";
}

span,
h3,
p,
input {
  font-family: "Akkurat Light";
}

h1 {
  font-family: "Akkurat Bold";
}
::selection {
  background: darkgray;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
